import React from 'react';
import css from './Tooltip.module.css';

function Tooltip(props) {
  const { text, tip } = props;
  return (
    <div className={css.tooltiptrigger}>
      {tip}
      <span className={css.tooltiptext}>{text}</span>
    </div>
  );
}
export default Tooltip;
