/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError, FieldSelect } from '../../components';

import css from './FieldCheckboxGroup.module.css';

const FieldCheckboxRenderer = props => {
  const {
    className,
    rootClassName,
    label,
    optionLabelClassName,
    twoColumns,
    id,
    fields,
    options,
    meta,
    quantityArry,
    formApi,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns
    ? classNames(css.list, css.twoColumns)
    : css.list;
  const isBlockBooking = props.isBlockBooking ? props.isBlockBooking : false;

  const handleChange = (e, eventType) => {
    if (eventType === 'checkbox' && !e.target.checked) {
      formApi.change(`discountPercent${e.target.value}`, '0');

      return;
    } else return;
  };

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          const textClassName = optionLabelClassName;
          const textClassNameMaybe = textClassName ? { textClassName } : {};
          return (
            <li
              key={fieldId}
              className={isBlockBooking ? css.blockBookItem : css.item}
            >
              <FieldCheckbox
                id={fieldId}
                name={fields.name}
                label={option.label}
                value={option.key}
                onChange={e => handleChange(e, 'checkbox')}
                {...textClassNameMaybe}
              />
              {isBlockBooking ? (
                <>
                  <FieldSelect
                    id={`discountPercent`}
                    className={css.quantityField}
                    name={`discountPercent${option.key}`}
                    disabled={
                      fields?.value?.includes(option.key) ? false : true
                    }
                  >
                    {quantityArry.map(num => (
                      <option key={num} value={num}>
                        {/* {fields?.value?.includes(option.key)
                          ? num
                          : quantityArry[0]}{' '} */}
                        {num}%
                      </option>
                    ))}
                  </FieldSelect>
                </>
              ) : null}
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldCheckboxGroup = props => (
  <FieldArray component={FieldCheckboxRenderer} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxGroup;
