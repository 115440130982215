import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  propTypes,
  LINE_ITEM_HOUR,
} from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, selectedBlockTime } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(
    item => item.code === code && !item.reversal
  );

  //used for display - package/charter can only be 1 day so we know its either package listing type or Day booking with only one day selected and we can display same text. Otherwise we can display the units x seats display
  const unitsBooked = unitPurchase.units;

  const quantity = unitPurchase?.units
    ? unitPurchase.units.toString()
    : unitPurchase?.quantity
    ? unitPurchase.quantity.toString()
    : null;
  const unitPrice = unitPurchase
    ? formatMoney(intl, unitPurchase.unitPrice)
    : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const message =
    unitPurchase?.seats > 1 ? (
      <FormattedMessage
        id={
          unitsBooked > 1
            ? `${translationKey}Seats`
            : `${translationKey}Bookings`
        }
        values={{ unitPrice, quantity, seats: unitPurchase.seats }}
      />
    ) : (
      <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
    );

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {/* <FormattedMessage
          id={`${translationKey}`}
          values={{ unitPrice, quantity }}
        /> */}
        {message}
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
