import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';

import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money } = sdkTypes;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, {
  loadAfterInitialRendering: 3000,
});

const PriceMaybe = props => {
  const { price, publicData, config, intl, blockBookingMaybe } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;

  const foundListingTypeConfig = validListingTypes.find(
    conf => conf.listingType === listingType
  );
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const { formattedPrice, priceTitle } = priceData(
    price,
    config.currency,
    intl
  );

  const priceText = () => {
    if (listingType === 'hourly-booking') {
      return `${formattedPrice} per hour`;
    } else if (listingType === 'daily-booking') {
      return `${formattedPrice} per day`;
    } else if (listingType === 'package-booking') {
      return `${formattedPrice} per person`;
    } else if (listingType === 'nightly-booking') {
      return `${formattedPrice} per night`;
    } else if (listingType === 'block-time-booking') {
      const lowestBlock = publicData?.blockBooking
        ? Math.min(...publicData.blockBooking)
        : null;
      const lowestPrice = lowestBlock ? price.amount * lowestBlock : null;
      const startingAtPrice = new Money(lowestPrice, price.currency);

      const formattedPrice = formatMoney(intl, startingAtPrice);

      return `Packages start at ${formattedPrice}`;
    } else if (listingType === 'inquiry') {
      return 'Free Inquiry (Price hidden)';
    } else {
      // for regular product selling --BC
      return formattedPrice;
    }
  };

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {priceText()}
      </div>
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const hasBusinessName = author.attributes.profile.publicData.businessName;
  const authorName = hasBusinessName
    ? hasBusinessName
    : author.attributes.profile.displayName;

  const selectedPhoto = publicData?.selectedPhoto
    ? publicData.selectedPhoto
    : null;

  const selectedPhotoObj = selectedPhoto
    ? currentListing.images.find(image => {
        return image.id.uuid === selectedPhoto;
      })
    : null;

  const firstImage = selectedPhotoObj
    ? selectedPhotoObj
    : currentListing.images && currentListing.images.length > 0
    ? currentListing.images[0]
    : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k =>
        k.startsWith(variantPrefix)
      )
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // pickupEnabled & shippingEnabled return undefined, not true/false, for
  // inquiry listings & all booking listings --BC
  const pickupEnabled = listing.attributes.publicData.pickupEnabled;
  const shippingEnabled = listing.attributes.publicData.shippingEnabled;
  const isInquiryListing =
    listing.attributes.publicData.listingType === 'inquiry';

  const deliveryInfoText = () => {
    if (pickupEnabled && shippingEnabled) {
      return '(Pickup & Shipping)';
    } else if (pickupEnabled) {
      return '(Pickup)';
    } else if (shippingEnabled) {
      return '(Shipping)';
    } else if (isInquiryListing) {
      return '(Inquire)';
    }
  };

  const deliveryInfo = (
    <span className={css.deliveryInfo}>{deliveryInfoText()}</span>
  );

  const blockBookingMaybe = listing.attributes.publicData.blockBooking;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          config={config}
          intl={intl}
          blockBookingMaybe={blockBookingMaybe}
        />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage
                id="ListingCard.author"
                values={{ authorName }}
              />
            </div>
          ) : null}
          {deliveryInfo}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
